// Variables, Mixins
@import 'includes/mixins';
@import './colors';
@import 'variables';
@import './colormap';
@import 'includes/bootstrap-custom';

@import 'global';

// Include Components
// @import '../components/**/*.scss';

// Include only selected components for mediapool!
@import 'mediapool';

// Theme - Map Colors to css selectors
.theme-dana {
  @import 'theme';
}
